import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SmartyStreetsCheckResult } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SmartyStreetsService {
  constructor(private http: HttpClient) {
  }

  checkUSAAddress(city: string, state: string, zip: string, address: string): Observable<SmartyStreetsCheckResult[]> {
    return this.http
      .get(`https://us-street.api.smartystreets.com/street-address?city=${city}&state=${state}&zipcode=${zip}&candidates=5&auth-token=${environment.smartyApiKey}&match=range&street=${address}`)
      .pipe(map((value: SmartyStreetsCheckResult[]) => value));
  }
}

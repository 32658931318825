import pick from 'ramda/es/pick';
import isNil from 'ramda/es/isNil';

export abstract class BaseModel {

  constructor(data?: any) {
    if (!data) {
      data = this.defaults();
    }
    this.hydrate(data);
  }

  hydrate(data: { [key: string]: any }): void {
    Object.getOwnPropertyNames(data).forEach((property) => {
      this[property] = data[property];
    });
  }

  extract(properties?: string[]) {
    if (isNil(properties)) {
      return JSON.parse(JSON.stringify(Object.assign({}, this)));
    } else {
      return pick(properties, this);
    }
  }

  abstract defaults();
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-more-apps',
  templateUrl: './more-apps.component.html',
  styleUrls: ['./more-apps.component.scss']
})
export class MoreAppsComponent implements OnInit {
  @Input() apps:any;
  @Input() appLogo:any;

  constructor( private matIconRegistry: MatIconRegistry,  private domSanitizer: DomSanitizer,
               private layoutService: LayoutService) { }

  ngOnInit(): void {

    this.matIconRegistry.addSvgIcon(
        'erpLogo',
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/erp_logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
        'taskManager',
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/task_manager_logo.svg")
    );
    this.matIconRegistry.addSvgIcon(
        'whereHouse',
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/where_house_logo.svg")
    );
  }

  onCancel(): void {
    this.layoutService.toggleAvailableAppsList(false);
  }

}

import { Observable, Subscriber, Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { ApiService } from '../services/api.service';
import { BaseModel } from './base.model';
import { PermissionsModel } from './permissions.model';

export class Collection<T> {

    private apiService: ApiService;
    private endpoint: string;
    private dataModel: any;

    private observable: Observable<T>;
    private subscriber: Subscriber<T>;

    private paramSearch: string;
    private paramPage: number;
    private paramLimit: number;
    private paramFilter: string;
    private paramSort: string;

    private total: number;
    private results: any[];
    private permisions: PermissionsModel;

    constructor(apiService: ApiService, endpoint: string, dataModel: new () => BaseModel) {
        this.apiService = apiService;
        this.endpoint = endpoint;
        this.dataModel = dataModel;

        this.paramPage = 1;
    }

    subscribe(next?: (value: T) => void, error?: (error: any) => void): Subscription {
        if (!this.observable) {
            this.observable = new Observable((subscriber) => {
                this.subscriber = subscriber;
            });
        }
        return this.observable.subscribe(next, error);
    }

    /**
     * "get" functions for params and results
     */

    getPage(): number {
        return this.paramPage;
    }

    getLastPage(): number | null {
        if (this.paramLimit && this.total) {
            return Math.ceil(this.total / this.paramLimit);
        }

        return null;
    }

    getRange(): number[] | null {
        if (this.paramPage && this.paramLimit && this.total) {
            return [
                (this.paramPage - 1) * this.paramLimit + 1,
                (this.total < this.paramLimit) ? this.total : this.paramPage * this.paramLimit
            ];
        }

        return null;
    }

    getLimit(): number {
        return this.paramLimit;
    }

    getFilter(): string {
        return this.paramFilter;
    }

    getSearch(): string {
        return this.paramSearch;
    }

    getSort(): string {
        return this.paramSort;
    }

    getTotal(): number {
        return this.total;
    }

    getResults(): T[] {
        return this.results;
    }

    getPermisions(): PermissionsModel {
        return this.permisions;
    }

    /**
     * "set" functions for params that will affect the results
     */

    setSearch(search: string | null): this {
        this.paramSearch = search;
        return this;
    }

    setPage(page: number | null): this {
        this.paramPage = page;

        return this;
    }

    setLimit(limit: number | null): this {
        this.paramLimit = limit;

        return this;
    }

    setFilter(filter: string | null): this {
        this.paramFilter = filter;

        return this;
    }

    setSort(sort: string | null): this {
        this.paramSort = sort;

        return this;
    }

    /**
     * Control functions that will launch a new request
     */

    request(): Observable<T[]> {
        const params = {
            page: (this.paramPage) ? this.paramPage.toString() : null,
            page_size: (this.paramLimit) ? this.paramLimit.toString() : null,
            s: (this.paramSearch) ? this.paramSearch.toString() : null,
            f: (this.paramFilter) ? this.paramFilter.toString() : null,
            o: (this.paramSort) ? this.paramSort.toString() : null
        };

        return this.apiService.get(this.endpoint, { params })
          .pipe(
            tap((response) => {
                this.total = response.total;
                this.results = this.buildResults(response.data);
                this.permisions = this.buildPermissions(response._permissions);
            }),
            tap(() => {
                if (!this.paramLimit) {
                    if (this.total > this.results.length) { this.setLimit(this.results.length); }
                }
            }),
            map(() => {
                return this.results;
            }),
            tap((response: any) => {
                if (this.subscriber) {
                    this.subscriber.next(response);
                }
            })
        );
    }

    nextPage() {
        return this.setPage(this.paramPage + 1).request();
    }

    prevPage() {
        return this.setPage(this.paramPage - 1).request();
    }

    goToPage(page: number) {
        return this.setPage(page).request();
    }

    changeLimit(limit: number) {
        let optimizedPage = 1;

        if (this.paramLimit && this.total && this.total > limit) {
            optimizedPage = Math.ceil((this.paramPage * this.paramLimit) / limit);
        }

        return this.setLimit(limit)
            .setPage(optimizedPage)
            .request();
    }

    search(value: string) {
        return this.setSearch(value)
            .setPage(1)
            .setFilter(null)
            .setSort(null)
            .request();
    }

    filter(filter: string) {
        return this.setFilter(filter)
            .setPage(1)
            .request();
    }

    sort(sort: string) {
        return this.setSort(sort)
            .setPage(1)
            .request();
    }

    /**
     * Internal functions
     */

    private buildPermissions(data: { [key: string]: any }) {
        return new PermissionsModel(data);
    }

    private buildResults(data: { [key: string]: any }[]) {
        return data.map((entry) => new this.dataModel(entry));
    }
}

import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export enum ERROR_KEYS {
  REQUIRED = 'required',
  INVALID_PHONE = 'invalidPhone',
  INVALID_PATTERN = 'patternMismatch',
  INVALID_EMAIL = 'email',
  INVALID_SALES_REP_PERCENTAGE = 'invalidPercentage'
}

@Injectable({
  providedIn: 'root'
})
export class FormsHelpersService {
  constructor() {
  }

  errorKeyMessage(controlFriendlyName: string, errorKey: string): string {
    switch (errorKey) {
      case ERROR_KEYS.REQUIRED:
        return `${controlFriendlyName} is required.`;

      case ERROR_KEYS.INVALID_PHONE:
        return `Not a valid phone number.`;

      case ERROR_KEYS.INVALID_EMAIL:
        return `Not a valid email format.`;

      case ERROR_KEYS.INVALID_PATTERN:
        return `That string is not matching the required format.`;


      case ERROR_KEYS.INVALID_SALES_REP_PERCENTAGE:
        return 'Split Percent sum must be <strong>100</strong>';

      default:
        return 'Invalid input.';
    }
  }

  getFormControlErrorMessages(control: FormControl, controlFriendlyName: string): string[] {
    const controlErrors: ValidationErrors = control.errors;

    if (!controlErrors) {
      return [];
    }

    return Object
      .keys(controlErrors)
      .map((errorKey: string) => this.errorKeyMessage(controlFriendlyName, errorKey));
  }
}

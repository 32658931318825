
<div class="bg-primary-100 border-bottom rounded-top  border-primary-600 d-flex"  mat-dialog-title >
  <div>
     <h1 class="fs-16 lh-18 text-black font-400 mb-0">
      {{data.header}} <span *ngIf="data.subHeader">- {{data.subHeader}}</span>
    </h1>
   </div>
   <button mat-icon-button class="close" [mat-dialog-close]="false">
     <mat-icon>clear</mat-icon>
   </button>
</div>
<div mat-dialog-content class="small">
  <form [formGroup]="form">
    <mat-form-field class="w-100">
      <mat-label>{{ data.patternLabel }}</mat-label>
      <input matInput formControlName="action" placeholder="">
    </mat-form-field>
    <mat-form-field *ngIf="data.requireReason" class="w-100">
      <mat-label>Type your reason for this action.</mat-label>
      <input matInput formControlName="reason" placeholder="">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false"> Cancel </button>
  <button mat-button color="accent" (click)="onConfirm()"> Ok </button>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {style, animate, transition, trigger} from '@angular/animations';
import { EventsService } from 'src/app/core/services';
import { NavItem } from '../components/sidebar/nav-item';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-erp-layout',
  templateUrl: './erp.component.html',
  styleUrls: ['./erp.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1})) 
      ]),
      transition(':leave', [
        animate(300, style({opacity:0})) 
      ])
    ])
  ]
})
export class ErpLayouComponent implements OnInit, OnDestroy {

  showHeader: boolean;
  lastScrollTop: number;
  stickyHeader = true;
  acitivityLogOpened: boolean;
  sideMenuOpened: boolean;
  notificationsListOpened: boolean;
  navItems: NavItem[];
  appsList: any;

  constructor(private eventsService: EventsService, private layoutService: LayoutService, private router: Router,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.showHeader = false;
    this.stickyHeader = false;
    this.lastScrollTop = 0;
  }

  ngOnInit() {
       
    this.matIconRegistry.addSvgIcon(
      'erpLogo',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/erp_logo.svg")
    );

    this.navItems = [
      {
        text: 'Dashboard',
        icon: 'dashboard',
        url: '/dashboard'
      },
      {
        text: 'Customers',
        icon: 'people_alt',
        url: '/customers/list',
        children: [
          {
            text: 'Customers',
            url: '/customers/list'
          },
          {
            text: 'Statements',
            url: '/customers/statements'
          },
          {
            text: 'Aging',
            url: '/customers/aging'
          },
          {
            text: 'Reports',
            url: '/customers/reports'
          }
        ]
      },
      {
        text: 'Leads',
        icon: 'person',
        url: '/lead-lists',
        children: [
          {
            text: 'Lead lists',
            url: '/lead-lists'
          }
        ]
      },
      {
        text: 'Sales',
        icon: 'shopping_cart',
        url: '/sales/orders',
        children: [
          {
            text: 'Orders & Quotes',
            url: '/sales/orders'
          },
          {
            text: 'Projects',
            url: '/sales/projects'
          },
          {
            text: 'Reports',
            url: '/sales/reports'
          }
        ]
      },
      {
        text: 'Receivables',
        icon: 'attach_money',
        url: '/receivables/invoices',
        children: [
          {
            text: 'Invoices',
            url: '/receivables/invoices'
          },
          {
            text: 'Credits',
            url: '/receivables/credits'
          },
          {
            text: 'Receipts',
            url: '/receivables/receipts'
          },
          {
            text: 'Reports',
            url: '/receivables/reports'
          }
        ]
      },
      {
        text: 'Inventory',
        icon: 'ballot',
        url: '/inventory/items',
        children: [
          {
            text: 'Items',
            url: '/inventory/items'
          },
          {
            text: 'Transfers',
            url: '/inventory/transfers'
          },
          {
            text: 'Adjustments',
            url: '/inventory/adjustments'
          },
          {
            text: 'Reports',
            url: '/inventory/reports'
          }
        ]
      },
      {
        text: 'Vendors',
        icon: 'villa',
        url: '/vendors',
        children: [
          {
            text: 'Vendors',
            url: '/vendors'
          },
          {
            text: 'Statements',
            url: '/vendors/statements'
          },
          {
            text: 'Aging',
            url: '/vendors/aging'
          },
          {
            text: 'Reports',
            url: '/vendors/reports'
          }
        ]
      },
      {
        text: 'Purchasing',
        icon: 'add_shopping_cart',
        url: '/purchasing/requisitions',
        children: [
          {
            text: 'Requisitions',
            url: '/purchasing/requisitions'
          },
          {
            text: 'Purchase Orders',
            url: '/purchasing/purchase-orders?status=approved'
          },
          {
            text: 'ETA',
            url: '/purchasing/shipments'
          },
          {
            text: 'Purchase Receipts',
            url: '/purchasing/purchase-receipts'
          },
          {
            text: 'Reports',
            url: '/purchasing/reports'
          }
        ]
      },
      {
        text: 'Payables',
        icon: 'monetization_on',
        url: '/payables/bills',
        children: [
          {
            text: 'Bills',
            url: '/payables/bills'
          },
          {
            text: 'Credits',
            url: '/payables/creditsap'
          },
          {
            text: 'Payments',
            url: '/payables/payments?status=enabled'
          },
          {
            text: 'Reports',
            url: '/payables/reports'
          }
        ]
      },
      {
        text: 'Warehouse',
        icon: 'home',
        url: '/warehouse/shipping?status=open',
        children: [
          {
            text: 'Shipping',
            url: '/warehouse/shipping?status=open'
          },
          {
            text: 'Reports',
            url: '/warehouse/reports'
          }
        ]
      },
      {
        text: 'Banking',
        icon: 'account_balance',
        url: '/bank/banks',
        children: [
          {
            text: 'Banks',
            url: '/bank/banks'
          },
          {
            text: 'Bank Transfers',
            url: '/bank/transfers'
          },
          {
            text: 'Bank Deposits',
            url: '/bank/deposits'
          },
          {
            text: 'Checks',
            url: '/bank/checks'
          },
          {
            text: 'Reports',
            url: '/bank/reports'
          }
        ]
      },
      {
        text: 'Accounting',
        icon: 'calculate',
        url: '/gl/accounts',
        children: [
          {
            text: 'Chart of Accounts',
            url: '/gl/accounts'
          },
          {
            text: 'Journal',
            url: '/gl/journal'
          },
          {
            text: 'Trial Balance',
            url: '/gl/trial-balance'
          },
          {
            text: 'Reports',
            url: '/gl/reports'
          }
        ]
      },
      // {
      //   text: 'Demo',
      //   icon: 'icon-remove-red-eye',
      //   children: [
      //     {
      //       text: 'Cards',
      //       url: '/demo/cards'
      //     },
      //     {
      //       text: 'Forms',
      //       url: '/demo/forms'
      //     },
      //     {
      //       text: 'Modals',
      //       url: '/demo/modals'
      //     },
      //     {
      //       text: 'Tables',
      //       url: '/demo/tables'
      //     }
      //   ]
      // }
    ];

    this.appsList = {
      active: [
        {
          name: 'Ticketing System',
          icon: 'taskManager',
          url: 'https://ticketing.phillipscollection.com/' 
        }
      ], 
      future: []
    }
  
    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('resize', this.resizeEvent, true);

    this.eventsService
      .subscribe('stickyHeader', ((isSticky: boolean) => {
        this.stickyHeader = isSticky;
      }));

    this.layoutService.showActivityLogEmitter.subscribe((val) => { this.acitivityLogOpened = val; });
    this.layoutService.showNotificationsListEmitter.subscribe((val) => { this.notificationsListOpened = val; });
    this.layoutService.toggleSideMenuEvent.subscribe((val) => { 
      setTimeout(()=>{                          
        this.sideMenuOpened = val;
      }, 100);
     });
  }


  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    window.removeEventListener('scroll', this.resizeEvent, true);
  }

  scrollEvent = (event: any): void => {
    if (this.stickyHeader) {
      this.showHeader = true;
      return;
    }

    let st = event.srcElement.scrollTop;
    if (st > this.lastScrollTop) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
    this.lastScrollTop = st;
  }

  resizeEvent  = (event: any): void => {
    let innerWidth = event.srcElement.innerWidth;
    
    if (innerWidth < 1440) {
      this.layoutService.toggleSideMenu(false);
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { style, animate, transition, trigger } from '@angular/animations';

import { EventsService } from 'src/app/core/services';
import { NavItem } from '../components/sidebar/nav-item';
import { LayoutService } from '../layout.service';
@Component({
  selector: 'app-ticketing-layout',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate(300, style({opacity: 1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity: 0}))
      ])
    ])
  ]
})
export class TicketingLayouComponent implements OnInit, OnDestroy {

  showHeader: boolean;
  lastScrollTop: number;
  stickyHeader = true;
  acitivityLogOpened: boolean;
  sideMenuOpened: boolean;
  notificationsListOpened: boolean;
  navItems: NavItem[];
  appsList: any;

  constructor(private eventsService: EventsService, private layoutService: LayoutService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.showHeader = false;
    this.stickyHeader = false;
    this.lastScrollTop = 0;
  }

  ngOnInit() {
    // TODO: create a global service, helpers, whatever to change the favicon and page title (visible in tab)
    // (document.getElementById('appIcon') as HTMLLinkElement).setAttribute('href', '../assets/img/icons/task_manager_logo.svg');

    this.navItems = [];
    this.appsList = {
      active: [
        {
          name: 'Chorus',
          icon: 'erpLogo',
          url: 'https://erp.phillipscollection.com/'
        }
      ],
      future: []
    };

    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('resize', this.resizeEvent, true);

    this.eventsService.subscribe('stickyHeader', ((isSticky: boolean) => {
      this.stickyHeader = isSticky;
    }));

    this.layoutService.showActivityLogEmitter.subscribe((val) => { this.acitivityLogOpened = val; });
    this.layoutService.showNotificationsListEmitter.subscribe((val) => { this.notificationsListOpened = val; });
    this.layoutService.toggleSideMenuEvent.subscribe((val) => {
      setTimeout(() => {
        this.sideMenuOpened = val;
      }, 100);
    });

    this.matIconRegistry.addSvgIcon('taskManagerLogo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/icons/task_manager_logo.svg'));
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    window.removeEventListener('resize', this.resizeEvent, true);
  }

  scrollEvent = (event: any): void => {
    if (this.stickyHeader) {
      this.showHeader = true;
      return;
    }

    const st = event.srcElement.scrollTop;
    if (st > this.lastScrollTop) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
    this.lastScrollTop = st;
  }

  resizeEvent  = (event: any): void => {
    let innerWidth = event.srcElement.innerWidth;
    
    if (innerWidth < 1440) {
      this.layoutService.toggleSideMenu(false);
    }
  }

}

import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { CreditCardDirectivesModule } from 'angular-cc-library';

import { MaterialModule } from './material.module';
import { SharedValidators } from './shared.validators';

// components
import { ListCollectionComponent } from './components/list/collection/collection.component';
import { ListFiltersComponent } from './components/list/filters/filters.component';
import { ListFabComponent } from './components/list/fab/fab.component';
import { AddressComponent } from './components/address/address.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ToolTipComponent } from './components/tool-tip/tool-tip.component';
import { SkelethonComponent } from './components/skelethon/skelethon.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { NestedMenuComponent } from './components/nested-menu/nested-menu.component';
import { AutocompleteChipsComponent } from './components/autocomplete-chips/autocomplete-chips.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InputChipsComponent } from './components/input-chips/input-chips.component';
import { InputFilesComponent } from './components/input-files/input-files.component';
import { StepComponent, StepperComponent } from './components/stepper/stepper.component';

// services
import { SmartyStreetsService } from './services/smarty-streets.service';
import { CountriesService } from './services/countries.service';
import { OverlayService } from './services/overlay.service';
import { FormsHelpersService } from './services/forms-helpers.service';

// directives
import { DefaultImageDirective } from 'src/app/core/directives/default-image.directive';
import { DragDropDirective } from 'src/app/core/directives/drag-drop.directive';
import { InputPatternOnlyDirective } from 'src/app/core/directives/input-pattern-only.directive';
import { ToolTipDirective } from 'src/app/core/directives/tool-tip.directive';
import { PhoneInputFormatDirective } from './directives/phone-input-format.directive';
import { TableRowExpansionDirective } from './directives/table-row-expansion.directive';
import { TimeAgoPipe } from 'time-ago-pipe';

// pipes
import { OptionsFilter } from './pipes/options-filter.pipe';
import { ReadableBooleanPipe } from './pipes/readable-boolean.pipe';
import { PreserveNewLinePipe } from './pipes/preserve-new-line.pipe';
import { NotAvailableInfoPipe } from './pipes/not-available-info.pipe';
import { MapIdsToValuePipe } from './pipes/map-ids-to-values.pipe';
import { JoinArrayProperty } from './pipes/join-array-property.pipe';
import { FormatPhonePipe } from './pipes/format-phone.pipe';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MapPipe } from './pipes/map.pipe';


// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoExtendsPipe extends TimeAgoPipe {}


@NgModule({
  declarations: [
    // components
    AddressComponent, // TODO: Refactor and rename into InputAddress
    AutocompleteChipsComponent, // TODO: replace this component with InputChips
    ConfirmActionComponent,
    CreditCardComponent,
    DialogComponent,
    FileUploadComponent, // TODO: replace this component with InputFiles
    FormControlErrorComponent,
    InputChipsComponent,
    InputFilesComponent,
    ListCollectionComponent,
    ListFabComponent, // TODO: remove this component (not needed in new design)
    ListFiltersComponent,
    NestedMenuComponent,
    PhoneInputComponent, // TODO: rename into InputPhone
    ProgressSpinnerComponent,
    SkelethonComponent,
    ToolTipComponent,
    StepComponent,
    StepperComponent,

    // directives
    DefaultImageDirective, // TODO: move files of this component from core to shared
    DragDropDirective, // TODO: move files of this component from core to shared
    InputPatternOnlyDirective, // TODO: move files of this component from core to shared
    PhoneInputFormatDirective,
    TableRowExpansionDirective,
    ToolTipDirective, // TODO: move files of this component from core to shared

    // pipes
    ArrayFilterPipe,
    FormatPhonePipe,
    JoinArrayProperty,
    MapPipe,
    MapIdsToValuePipe,
    NotAvailableInfoPipe,
    OptionsFilter,
    PreserveNewLinePipe,
    ReadableBooleanPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    TimeAgoExtendsPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    OverlayModule
  ],
  exports: [
    // components
    AddressComponent,
    AutocompleteChipsComponent,
    ConfirmActionComponent,
    CreditCardComponent,
    DialogComponent,
    FileUploadComponent,
    FormControlErrorComponent,
    InputChipsComponent,
    InputFilesComponent,
    ListCollectionComponent,
    ListFabComponent,
    ListFiltersComponent,
    NestedMenuComponent,
    PhoneInputComponent,
    ProgressSpinnerComponent,
    SkelethonComponent,
    ToolTipComponent,
    StepComponent,
    StepperComponent,

    // directives
    DefaultImageDirective,
    DragDropDirective,
    InputPatternOnlyDirective,
    PhoneInputFormatDirective,
    TableRowExpansionDirective,
    ToolTipDirective,

    // pipes
    ArrayFilterPipe,
    FormatPhonePipe,
    JoinArrayProperty,
    MapIdsToValuePipe,
    NotAvailableInfoPipe,
    OptionsFilter,
    PreserveNewLinePipe,
    ReadableBooleanPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    TimeAgoExtendsPipe
  ],
  providers: [
    SmartyStreetsService,
    CountriesService,
    OverlayService,
    SharedValidators,
    FormsHelpersService
  ]
})
export class SharedModule { }

import { FILTER_TYPE_MULTI_SELECT, FILTER_TYPE_RANGE } from 'src/app/shared/components/list/filters/filters.component';
import { Component, OnInit, Input, ContentChildren, QueryList, AfterContentInit, ViewChild, ContentChild, Output, EventEmitter, OnDestroy, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ThemePalette } from '@angular/material/core';
import { MatColumnDef, MatTable, MatHeaderRowDef, MatRowDef, MatFooterRowDef } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { Collection } from 'src/app/core/models/collection.model';
import { ListFilter } from '../filters/filters.component';
import { DOCUMENT } from '@angular/common';

export interface Config {
  search?: boolean;
  bindUrlParams?: boolean;
  fixedFooter?: boolean;
  stickyHeaders?: boolean;
}

interface CollectionQueryParams {
  tab: string;
  search: string;
  sort: string;
  page: number;
  limit: number;
  highlight?: string;
}

interface CollectionQueryFilters {
  [key: string]: string;
}

export interface ListTab {
  id: string;
  label: string;
  filter: string;
  icon?: string;
  count?: number;
  color?: string;
  disabled?: boolean;
}
export interface ChildFab {
  id: string | number;
  icon: string;
  iconColor?: ThemePalette;
  imgUrl?: string;
  tooltip?: string;
  color?: ThemePalette;
  callback: () => void;
}
export interface ListFab {
  icon?: string;
  color?: ThemePalette;
  children?: ChildFab[];
  callback?: () => void;
}
export interface RowAction {
  icon: string;
  label: string;
  callback: (row?: any) => void;
}
export interface BulkAction {
  icon: string;
  callback: (rows?: []) => void;
}
export interface Pagination {
  pageSizeOptions?: number[];
  pageSize?: number;
}

export interface CollectionInfo {
  queryFilters: string;
  tabsFilters: string;
  search: string;
  tab: ListTab;
}

@Component({
  selector: 'app-list-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class ListCollectionComponent<T> implements OnInit, AfterContentInit, OnDestroy, OnChanges {

  @ViewChild(MatTable, { static: true }) matTable: MatTable<T>;
  @ViewChild('searchInput', { read: MatInput }) searchInput: MatInput;
  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;

  @ContentChild(MatSort) matSort: MatSort;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatFooterRowDef) footerRowDefs: QueryList<MatFooterRowDef>;

  @Input() collection: Collection<T>;
  @Input() displayedColumns: string[];
  @Input() config: Config;
  @Input() tabs: ListTab[];
  @Input() fab: ListFab;
  @Input() filters: ListFilter[] = [];
  @Input() rowActions: RowAction[];
  @Input() bulkActions: BulkAction[];
  @Input() pagination: Pagination;
  @Input() dependenciesFetched: boolean;

  /** Triggered only once after initialization was done */
  @Output() init: EventEmitter<CollectionInfo> = new EventEmitter();
  /** Triggered before each api request with "true" and after each complete api request with false */
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  /** Triggered after each list change (after successful api request) */
  @Output() search: EventEmitter<CollectionInfo> = new EventEmitter();
  /** Triggered after each filter change (after successful api request) */
  @Output() filter: EventEmitter<CollectionInfo> = new EventEmitter();
  /** Triggered every time a tab is clicked (before making the api request) */
  @Output() tab: EventEmitter<CollectionInfo> = new EventEmitter();
  /** Triggered every time a sorting header is clicked (before making the api request) */
  @Output() sort: EventEmitter<Sort> = new EventEmitter();
  /** Triggered on page changed or number of results per page (before making the api request) */
  @Output() page: EventEmitter<PageEvent> = new EventEmitter();
  /** Triggered every time any row is clicked */
  @Output() rowClick: EventEmitter<string> = new EventEmitter();

  data: T[] = [];
  total: number;

  selection: SelectionModel<T>;
  selectedTab: ListTab;
  appliedFilters: ListFilter[] = [];
  queryFilters = '';
  tabsFilters = '';

  showHeader = false;
  showFooter = false;
  showFilters = false;
  showSearchInput = false;
  bindUrlParams: boolean;

  lastScrollTop = 0;
  highlightEntityId: string;
  filterQueryPropertyPrefix = 'f_';
  isFetching = true;

  constructor(private router: Router, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) {}

  ngOnDestroy() {
    window.removeEventListener('scroll', this._scrollEvent, true);
  }

  ngOnInit() {
    window.addEventListener('scroll', this._scrollEvent, true);

    if (!this.collection) {
      throw new Error('Collection input is required for ListBuilderComponent');
    }

    if (this.config) {
      if (this.config.bindUrlParams === true) {
        this.bindUrlParams = true;
      }
    }

    if (this.rowActions) {
      this.displayedColumns.push('_actions');
    }

    if (this.bulkActions) {
      this.selection = new SelectionModel<T>(true, []);
      this.displayedColumns.unshift('_select');
    }

    if (this.tabs && !this.selectedTab) {
      this.selectedTab = this.tabs[0];
      this.collection.setFilter(this._prepareFilter());
    }

    if (this.pagination) {
      if (this.pagination.pageSize) {
        this.collection = this.collection.setLimit(this.pagination.pageSize);
      } else if (!!this.pagination.pageSizeOptions) {
        this.collection = this.collection.setLimit(this.pagination.pageSizeOptions[0]);
      }
    }

    this.data = this.getPlaceholderData();
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => {
      this.matTable.addColumnDef(columnDef);
      this.showHeader = this.showHeader || !!columnDef.headerCell;
      this.showFooter = this.showFooter || !!columnDef.footerCell;
    });
    this.headerRowDefs.forEach(headerRowDef => this.matTable.addHeaderRowDef(headerRowDef));
    this.rowDefs.forEach(rowDef => this.matTable.addRowDef(rowDef));
    this.footerRowDefs.forEach(footerRowDef => this.matTable.addFooterRowDef(footerRowDef));

    if (this.matSort) {
      this.matSort.sortChange.subscribe(this.changeSorting.bind(this));
    }

    if (this.pagination) {
      this.matPaginator.page.subscribe(this.changePagination.bind(this));
    }

    // disable some functions if loading
    this.loading.subscribe((value: boolean) => {
      this.isFetching = value;
      if (this.matSort) {
        this.matSort.disabled = value;
      }
      if (value) {
        this.data = this.getPlaceholderData();
      }
    });

    // subscribe for collection data
    this.collection.subscribe(() => {
      this.data = this.collection.getResults();
      this.total = this.collection.getTotal();

      if (this.pagination) {
        this.matPaginator.pageIndex = this.collection.getPage() - 1;
        this.matPaginator.pageSize = this.collection.getLimit();
      }
      if (this.bindUrlParams) {
        this._pushUrlParams();
      }
      if (this.highlightEntityId) {
        this._scrollToHighlightedEntity();
      }

      this.loading.emit(false);
    });

    this.init.emit(this.info());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dependenciesFetched && changes.dependenciesFetched.currentValue && changes.dependenciesFetched.currentValue !== changes.dependenciesFetched.previousValue) {
      this.load();
    }
  }

  load() {
    if (this.bindUrlParams) {
      this._loadUrlParams();
    }

    this.collection.request().subscribe();
  }

  info(): CollectionInfo {
    return {
      queryFilters: this.queryFilters,
      tabsFilters: this.tabsFilters,
      search: this.collection.getSearch(),
      tab: this.selectedTab
    };
  }

  private getPlaceholderData() {
    const limit = this.collection.getLimit() || 10;

    return Array(limit).fill(undefined);
  }

  private _scrollEvent(event: any): void {
    const st = event.srcElement.scrollTop;
    if (st > this.lastScrollTop) {
      this.showFilters = false;
    } else {
      this.showFilters = true;
    }
    this.lastScrollTop = st;
  }

  getListFiltersFromParams(queryParams: ParamMap): ListFilter[] {
    return queryParams.keys
      .filter((key: string) => key.indexOf(this.filterQueryPropertyPrefix) === 0)
      .map((key: string) => {
        const filterId = key.replace(this.filterQueryPropertyPrefix, '');
        const filterValue = queryParams.get(key);
        const foundFilter = this.filters.find((item: ListFilter) => item.id === filterId);

        if (!foundFilter) { return; }

        if (!foundFilter.values) {
          foundFilter.values = [];
        }

        switch (foundFilter.type) {
          case FILTER_TYPE_MULTI_SELECT:
          case FILTER_TYPE_RANGE:
            foundFilter.values = filterValue.split(',');
            break;
          default:
            foundFilter.values.push(filterValue);
            break;
        }

        return foundFilter;
      })
      .filter((item?: ListFilter) => !!item);
  }

  private _loadUrlParams() {
    const queryParams = this.route.snapshot.queryParamMap;

    this.appliedFilters = this.getListFiltersFromParams(queryParams);

    if (this.tabs && queryParams.has('tab')) {
      this.tabs
        .forEach((tab) => {
          if (tab.id === queryParams.get('tab')) {
            this.selectedTab = tab;
          }
        });
      this.collection.setFilter(this._prepareFilter());
    }

    if (this.pagination) {
      if (queryParams.has('page')) {
        this.collection.setPage(parseInt(queryParams.get('page'), 10));
      }
      if (queryParams.has('limit')) {
        const limit = parseInt(queryParams.get('limit'), 10);
        if (!!this.pagination.pageSizeOptions && this.pagination.pageSizeOptions.includes(limit)) {
          this.collection.setLimit(limit);
        }
      }
    }

    if (this.matSort && queryParams.has('sort')) {
      this.collection.setSort(queryParams.get('sort'));
    }

    if (!!this.config && this.config.search && queryParams.has('search')) {
      this.searchInput.value = queryParams.get('search');
      this.collection.setSearch(this.searchInput.value);
      this.showSearchInput = true;
    }

    if (queryParams.has('highlight')) {
      this.highlightEntityId = queryParams.get('highlight');
    }

    if (this.appliedFilters.length) {
      this.queryFilters = this.getQueryFiltersString(this.appliedFilters);
      this.tabsFilters = this._prepareFilter('applied');
      const filterString = this._prepareFilter();
      this.collection.setFilter(filterString).setPage(1);
    }
  }

  private _scrollToHighlightedEntity() {
    setTimeout(() => {
      const element = this.document.getElementById(this.highlightEntityId);
      if (element) {
        element.scrollIntoView({ block: 'center' });
      }
    });
  }

  private _resetHighlightedEntity() {
    this.highlightEntityId = '';
  }

  private _pushUrlParams() {
    const tab = this.selectedTab ? this.selectedTab.id : null;
    const search = this.collection.getSearch();
    const sort = this.collection.getSort();
    const page = this.collection.getPage();
    const limit = this.collection.getLimit();

    const queryParamsFilter: CollectionQueryFilters = {};

    this.appliedFilters
      .forEach((listFilter: ListFilter) => {
        queryParamsFilter[`${this.filterQueryPropertyPrefix}${listFilter.id}`] = listFilter.values.join(',');
      });

    const queryParams: CollectionQueryParams = { tab, search, sort, page, limit };

    if (this.highlightEntityId) {
      queryParams.highlight = this.highlightEntityId;
    }
    return this.router.navigate([], { queryParams: { ...queryParams, ...queryParamsFilter } });
  }

  private _prepareFilter(type?: 'all' | 'tab' | 'applied') {
    let filter = '';
    const tabFilters = this.selectedTab ? this.selectedTab.filter : null;
    const appliedFilters = this.queryFilters;

    switch (type) {
      case 'tab':
        filter = tabFilters;
        break;
      case 'applied':
        filter = appliedFilters;
        break;
      default:
      case 'all':
        filter = [tabFilters, appliedFilters].filter(el => el).join(' and ');
        break;
    }

    return filter;
  }

  changePagination(event: PageEvent): void {
    this.loading.emit(true);
    this._resetHighlightedEntity();

    this.collection.setPage(event.pageIndex + 1).setLimit(event.pageSize);
    this.page.emit(event);
    this.collection.request().subscribe();
  }

  changeSorting(event: Sort) {
    this.loading.emit(true);
    const value = (event.direction === 'desc' ? '-' : '') + event.active;

    const request = this.collection.sort(value);
    this.sort.emit(event);
    request.subscribe();
  }

  _searchSubmit(el: HTMLInputElement): void {
    this.changeSearch(el.value);
    el.blur();
  }

  changeSearch(value: string) {
    this._resetHighlightedEntity();
    this.loading.emit(true);
    const filter = this._prepareFilter();

    this.collection.setSearch(value).setPage(1).setFilter(filter).setSort(null);
    this.search.emit(this.info());
    this.collection.request().subscribe();
  }

  changeTab(tab: ListTab) {
    this._resetHighlightedEntity();
    this.loading.emit(true);
    this.selectedTab = tab;
    const filter = this._prepareFilter();

    this.collection.setFilter(filter).setPage(1);
    this.tab.emit(this.info());
    this.collection.request().subscribe();
  }

  getQueryFiltersString(filters: ListFilter[]) {
    const parts = [];

    filters.forEach((filter: ListFilter) => {
      let values = filter.values.slice(0);

      if (filter.onUpdate) {
        values = filter.onUpdate(values);
      }
      filter.query.forEach((query: string) => {
        if (query.indexOf('{{value}}') >= 0) {
          let value = values.shift();
          // TODO: Understand what should happen here and fix the filters
          // if (value === undefined && query.includes('ge')) {
          //   value = 0;
          // } else if (value === undefined && query.includes('le')) {
          //   value = new Date().getTime().toString();
          // }
          // if (filter.range.type === 'date') {
          //   const date = Math.round(Number(value) / 1000);
          //   console.log(date);
          //   value = date.toString();
          // }
          parts.push(query.replace(/{{value}}/g, value.toString()));
        } else {
          parts.push(query);
        }
      });
    });

    return parts.join(' and ');
  }

  addFilter(filter: ListFilter) {
    this.appliedFilters.push(filter);
    this.applyFilters();
  }

  removeFilter(filter: ListFilter) {
    this.appliedFilters = this.appliedFilters.filter((item: ListFilter) => item.id !== filter.id);
    this.applyFilters();
  }

  resetFilters() {
    this.appliedFilters = [];
    this.applyFilters();
  }

  applyFilters() {
    this.loading.emit(true);
    this._resetHighlightedEntity();

    this.queryFilters = this.getQueryFiltersString(this.appliedFilters);
    this.tabsFilters = this._prepareFilter('applied');

    const filterString = this._prepareFilter();
    this.collection.setFilter(filterString).setPage(1);

    this.filter.emit(this.info());
    this.collection.request().subscribe();
  }

  onRowClick(entityId: string) {
    if (this.isFetching) {
      return;
    }
    this.highlightEntityId = entityId;
    this._pushUrlParams()
      .then(() => {
        this.rowClick.emit(entityId);
      });
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  _isAllSelected() {
    return this.selection.selected.length === this.data.length;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  _masterSelect() {
    this._isAllSelected() ? this.selection.clear() : this.data.forEach(row => this.selection.select(row));
  }

  _toggleSearchInput() {
    this.showSearchInput = !this.showSearchInput;
    if (this.showSearchInput) {
      this.searchInput.focus();
    }
  }

  _clearSearchInput() {
    this.searchInput.value = null;
    this.changeSearch(this.searchInput.value);
    this.searchInput.focus();
  }

  onFabClick(id?: string | number) {
    if (id) {
      this.fab.children.find((fab: ChildFab) => fab.id === id).callback();
    } else {
      this.fab.callback();
    }
  }
}

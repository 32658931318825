import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface ConfirmActionModalData {
  header: string;
  subHeader: string;
  pattern: string;
  patternLabel: string;
  requireReason: boolean;
}

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent {

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<ConfirmActionComponent>, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: ConfirmActionModalData) {
    this.form = this.fb.group({
      action: ['', [Validators.required, Validators.pattern(`^${this.data.pattern}$`)]]
    });

    if (this.data.requireReason) {
      this.form.addControl('reason', new FormControl('', [Validators.required, Validators.minLength(5)]));
    }
  }

  onConfirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }

    const value = (this.data.requireReason) ? this.form.controls.requireReason.value : true;

    this.dialogRef.close(value);
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './modules/auth/services/auth.service';
import { EventsService } from './core/services/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Yeti';

  constructor(private authService: AuthService, private router: Router, private events: EventsService) {
    this.initializeApp();
  }

  private initializeApp() {
    this.events.subscribe('user:logout', () => {
      this.router.navigate(['auth']);
    });
    this.events.subscribe('api:unauthorized', () => {
      this.authService.logout().subscribe();
    });
  }
}

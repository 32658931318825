import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Validators } from '@angular/forms';

import { Country, CountryState, SmartyStreetsCheckResult } from 'src/app/shared/interfaces';
import { SmartyStreetsService } from 'src/app/shared/services';
import { SharedValidators } from '../../shared.validators';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string;
  fax?: string;
  email: string;
  web: string;
  rdi: string;
  notes: string;
  isValid: string;
}

enum ADDRESS_VALID_TYPES {
  INVALID_ADDRESS_TYPE = 'invalid',
  API_VALIDATED_ADDRESS_TYPE = 'api_validated',
  USER_VALIDATED_ADDRESS_TYPE = 'user_validated'
}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input()
  addressForm: FormGroupTyped<Address>;

  @Input()
  countries: Country[];

  @Input()
  addressLabel?: string = 'Billing Address *';

  showAddressNotValidatedMessage: boolean;
  smartySuggestions: SmartyStreetsCheckResult[];
  showAddressCheckedBySmartyMessage: boolean;

  countriesIso3CodesWithStates: string[] = ['USA', 'CAN'];

  constructor(
    private smartyStreetService: SmartyStreetsService,
    private sharedValidators: SharedValidators
  ) {
    this.showAddressNotValidatedMessage = false;
    this.showAddressCheckedBySmartyMessage = false;
    this.smartySuggestions = [];
  }

  ngOnInit() {
    this.addressForm.get('country').valueChanges
      .subscribe((countryCodeIso3: string) => {
        if (this.addressForm.get('phone') && this.addressForm.get('fax')) {
          const foundCountry = this.countries.find((country: Country) => country.iso3 === countryCodeIso3);
          const countryPhoneValidator = this.sharedValidators.validatePhoneNumber((foundCountry && foundCountry.iso2) || 'US');
          this.addressForm.get('phone').setValidators([Validators.required, countryPhoneValidator]);
          this.addressForm.get('fax').setValidators([countryPhoneValidator]);
          this.addressForm.get('phone').updateValueAndValidity();
          this.addressForm.get('fax').updateValueAndValidity();
        }

        if (this.countriesIso3CodesWithStates.includes(countryCodeIso3)) {
          this.addressForm.get('state').setValidators([Validators.required]);
        } else {
          this.addressForm.get('state').setValidators([]);
        }

        this.addressForm.get('state').setValue('');
        this.addressForm.get('state').updateValueAndValidity();
      });
  }

  setAddressValidType(addressValidType: string) {
    this.addressForm.get('isValid').setValue(addressValidType);
  }

  useSuggestion(smartySuggestion: SmartyStreetsCheckResult) {
    this.addressForm.get('address').setValue(smartySuggestion.delivery_line_1);
    this.addressForm.get('city').setValue(smartySuggestion.components.city_name);
    this.addressForm.get('zip').setValue(`${smartySuggestion.components.zipcode}-${smartySuggestion.components.plus4_code}`);
    this.addressForm.get('state').setValue(smartySuggestion.components.state_abbreviation);
    this.addressForm.get('rdi').setValue(smartySuggestion.metadata.rdi);
    this.setAddressValidType(ADDRESS_VALID_TYPES.API_VALIDATED_ADDRESS_TYPE);

    this.smartySuggestions = [];
    this.showAddressCheckedBySmartyMessage = false;
  }

  verifyAddress() {
    this.smartyStreetService
      .checkUSAAddress(
        this.addressForm.get('city').value,
        this.addressForm.get('state').value,
        this.addressForm.get('zip').value,
        this.addressForm.get('address').value)
      .pipe(
        catchError(() => {
          return of([]);
        }))
      .subscribe((smartySuggestions: SmartyStreetsCheckResult[]) => {
        this.showAddressNotValidatedMessage = false;
        this.smartySuggestions = smartySuggestions;
        const smartySuggestion = this.smartySuggestions && this.smartySuggestions[0];

        const exactMatchFound = smartySuggestion
          && smartySuggestion.components.city_name === this.addressForm.get('city').value
          && smartySuggestion.delivery_line_1 === this.addressForm.get('address').value
          && this.addressForm.get('zip').value.indexOf(smartySuggestion.components.zipcode)
          && smartySuggestion.components.state_abbreviation === this.addressForm.get('state').value;

        if (exactMatchFound) {
          if (smartySuggestion.components.plus4_code) {
            this.addressForm.get('zip').setValue(`${smartySuggestion.components.zipcode}-${smartySuggestion.components.plus4_code}`);
          }
          this.addressForm.get('rdi').setValue(smartySuggestion.metadata.rdi || '');
          this.setAddressValidType(ADDRESS_VALID_TYPES.API_VALIDATED_ADDRESS_TYPE);
        } else {
          this.showAddressCheckedBySmartyMessage = true;
        }
      });
  }

  addressChanged() {
    this.smartySuggestions = [];
    this.showAddressCheckedBySmartyMessage = false;

    if (this.addressForm.get('country').value === 'USA') {
      this.showAddressNotValidatedMessage = true;
      this.setAddressValidType(ADDRESS_VALID_TYPES.INVALID_ADDRESS_TYPE);
    } else {
      this.showAddressNotValidatedMessage = false;
      this.setAddressValidType(ADDRESS_VALID_TYPES.USER_VALIDATED_ADDRESS_TYPE);
    }
  }

  selectedCountryStates(): CountryState[] {
    const countryISO = this.addressForm.get('country').value;
    const country = this.countries.find((item: Country) => item.iso3 === countryISO);
    return (country && country.states && country.states) || [];
  }

  onAddressUserValidateCheckboxChanged({ checked }: MatCheckboxChange): void {
    if (checked) {
      this.setAddressValidType(ADDRESS_VALID_TYPES.USER_VALIDATED_ADDRESS_TYPE);
    } else {
      this.setAddressValidType(ADDRESS_VALID_TYPES.INVALID_ADDRESS_TYPE);
    }
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';
import { CreditCardModel } from '../../models';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  creditCardForm: FormGroup;
  type: string;

  @Input()
  loading: boolean;

  @Input()
  title: string;

  @Output()
  cancel: EventEmitter<void> = new EventEmitter();

  @Output()
  save: EventEmitter<CreditCardModel> = new EventEmitter();

  types: string[] = ['maestro', 'forbrugsforeningen', 'dankort', 'visa', 'mastercard', 'amex', 'dinersclub', 'discover', 'unionpay', 'jcb'];

  flipped: boolean;

  cardNumberChanged(event: Event) {
    const element = event.target as HTMLInputElement;
    const type = this.types.find((item: string) => element.classList.contains(item));
    this.type = type || '';
  }

  constructor(private fb: FormBuilder) {
    this.creditCardForm = this.fb.group({
      number: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      holderName: ['', Validators.required],
      expiration: ['', [CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    const [month, year] = this.creditCardForm.get('expiration').value.split('/');
    const creditCard = {
      number: this.creditCardForm.get('number').value,
      holderName: this.creditCardForm.get('holderName').value,
      cvc: this.creditCardForm.get('cvc').value,
      type: this.type,
      expiration: {
        month: parseInt(month, 10),
        year: parseInt(year, 10)
      }
    };
    this.save.emit(new CreditCardModel({ creditCard }));
  }

  formInputFocus(event: Event) {
    const element = event.target as HTMLInputElement;
    this.flipped = element.id === 'cvc';
  }

  getCardNumberSections() {
    const cardNumber = this.creditCardForm.get('number').value;
    const sections = cardNumber.split(' ');
    return sections
      .map((section: string, index: number) => index > 2 ? section : section.replace(/./g, '*'));
  }
}

<header id="header" class="bg-white">
	<div class="container px-xl-5 px-lg-3 px-md-3 px-sm-2 px-xs-2">
		<div class="row justify-content-between">
			<div class="col page-title-wrapper text-truncate">
				<h1 class="m-0 align-middle text-accent-950 text-truncate">{{pageTitle}}</h1> 
			</div>
			<div class="col-auto align-self-center">
				<div class="float-right">
					<app-search class="align-middle"></app-search>
					<div class="header-actions align-middle">
						<!-- <i class="material-icons-outlined md-icon text-primary-750 align-middle d-inline-block ml-3" (click)="toggleNotificationsList(true)" [ngClass]="{'material-icons-outlined': !activeNotificationBtn, 'material-icons active':activeNotificationBtn}">notifications_active</i> -->
						<!-- <i class="material-icons-outlined md-icon text-primary-750 align-middle d-inline-block ml-3" (click)="toggleActivitiesList(true)" [ngClass]="{'material-icons-outlined': !activeActivitiesBtn, 'material-icons active':activeActivitiesBtn}">schedule</i> -->
						<app-user-menu class="align-middle d-inline-flex pr-1"></app-user-menu>
						<button mat-flat-button color="accent" class="ml-3 align-middle create-document" *ngIf="newButton" (click)="newButton.callback()">
							<span class="d-flex">
								<i class="material-icons align-self-center">add</i> 
								<span class="align-self-center btn-label">{{newButton.label}}</span>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
<div *ngIf="fullSideMenu" class="full-side-nav">
  <div class="logo mt-4">
    <span class="d-inline-flex cursor-pointer" [routerLink]="[homeUrl]">
      <mat-icon [svgIcon]="appLogo"  class="align-self-center mr-20"  color="accent"></mat-icon>
      <span class="align-self-center">CHORUS</span>
    </span>
    <span class="menu-toggle" (click)="toggleMenu()">
      <span class="material-icons-outlined">chevron_left</span>
    </span>
  </div>
  <div class="nav-wrapper">
    <mat-accordion class="sidebar">
      <mat-expansion-panel class="mat-elevation-z" *ngFor="let item of navItems" routerLinkActive="active"  [disabled]="!item.children?.length" >
        <mat-expansion-panel-header  *ngIf="item.children?.length">
          <mat-panel-title expandedHeight="72" collapsedHeight="72">
            <span class="d-flex">
              <i *ngIf="item.icon" class="material-icons-outlined align-self-center">{{item.icon}}</i>
              <span class="align-self-center"> {{ item.text }}</span>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
          <mat-expansion-panel-header  *ngIf="!item.children?.length"  [routerLink]="item.url">
            <mat-panel-title expandedHeight="72" collapsedHeight="72"> 
              <span class="d-flex">
                <i *ngIf="item.icon" class="material-icons-outlined align-self-center">{{item.icon}}</i>
                <span class="align-self-center"> {{ item.text }}</span>
              </span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list *ngIf="item.children?.length">
          <a mat-list-item [routerLink]="child.url" routerLinkActive="active" *ngFor="let child of item.children">{{child.text }}</a>
        </mat-nav-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="more-apps available-apps d-flex cursor-pointer"  (click)="openAppsList()"  *ngIf="appsList?.active.length || appsList?.future.length">
    <span class="text-center rounded-circle d-flex all-apps justify-content-center"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">apps</i></span>
    <span class="align-self-center">Switch Application</span>
  </div>
</div>

  <div class="side-nav"  *ngIf="!fullSideMenu">
    <div class="alt-logo mt-4 mb-4 text-center">
      <mat-icon [svgIcon]="appLogo"  class="align-self-center mx-auto cursor-pointer" color="accent" [routerLink]="[homeUrl]"></mat-icon>
      <div class="menu-toggle-wrapper">
        <!-- <span class="menu-toggle-show m-auto">
          <span class="material-icons-outlined">expand_more</span>
        </span>
        <span class="menu-toggle m-auto"  (click)="toggleMenu()">
          <span class="material-icons-outlined">chevron_right</span>
        </span> -->
      </div>
      
    </div>
    <div class="nav-wrapper">
      <div>
        <div *ngFor="let item of navItems" class="nav-item" routerLinkActive="active">
          <i  [routerLink]="item.url"  class="material-icons-outlined" >{{item.icon}}</i>
          <mat-nav-list *ngIf="item.children?.length">
            <a mat-list-item [routerLink]="child.url" routerLinkActive="active" *ngFor="let child of item.children">{{child.text }}</a>
          </mat-nav-list>
        </div>
      </div>
    </div>
    <div class="text-center more-apps" *ngIf="appsList?.active.length || appsList?.future.length">
      <span class="text-center rounded-circle d-flex all-apps m-auto justify-content-center"  (click)="openAppsList()"><i class="material-icons-round text-grey-1000 mr-0 align-self-center">apps</i></span>
    </div>
  </div>
  <app-more-apps *ngIf="showAppsList" [apps]="appsList" [appLogo]="appLogo" [@fadeInOut]></app-more-apps>

<mat-card>
  <mat-card-header class="border-bottom  border-primary-600">
    <mat-card-title>
      {{title}}
    </mat-card-title>

    <mat-icon class="close"
              (click)="onCancel();"> clear </mat-icon>
  </mat-card-header>
  <mat-card-content class="default-size">
    <mat-spinner *ngIf="loading"
                 class="mx-auto my-5"
                 [color]="['accent']"></mat-spinner>

    <div *ngIf="!loading"
         class="container p-4">

      <div id="card-preview"
           [ngClass]="{'flipped': flipped}">
        <div id="card">
          <div id="front"
               [ngClass]="type">
            <div class="number">
              <span class="value"
                    *ngFor="let cardNumberSection of this.getCardNumberSections()">
                {{cardNumberSection}}
              </span>
            </div>
            <div class="labels">
              <label>Card Holder</label>
              <label>Expiration</label>
            </div>
            <div class="values">
              <span class="value">{{this.creditCardForm.get('holderName').value}}</span>
              <span class="value">{{this.creditCardForm.get('expiration').value}}</span>
            </div>
          </div>
          <div id="back"
               [ngClass]="type">
            <div class="cvv">
              <span class="value">
                {{this.creditCardForm.get('cvc').value}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div [formGroup]="creditCardForm"
           class="credit-card-form">
        <div class="row">
          <div class="col">

            <mat-form-field class="w-100"
                            [floatLabel]="'always'">
              <mat-label>Card number</mat-label>
              <input matInput
                     ccNumber
                     id="cc-number"
                     type="tel"
                     autocomplete="cc-number"
                     (change)="cardNumberChanged($event)"
                     (focus)="formInputFocus($event)"
                     placeholder="Enter card number here:"
                     formControlName="number">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">

            <mat-form-field class="w-100"
                            [floatLabel]="'always'">
              <mat-label>Card holder</mat-label>
              <input matInput
                     (focus)="formInputFocus($event)"
                     appInputPatternOnly="[a-z,A-Z\s]"
                     placeholder="Enter card holder's name here:"
                     formControlName="holderName">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="credit-card-expiration-container">
              <mat-form-field class="w-100 expiration-field"
                              [floatLabel]="'always'">
                <mat-label>Expiration</mat-label>
                <input matInput
                       id="cc-exp-date"
                       formControlName="expiration"
                       (focus)="formInputFocus($event)"
                       type="tel"
                       autocomplete="cc-exp"
                       ccExp
                       maxlength="7"
                       placeholder="MM/YY">
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-6">
            <mat-form-field class="w-100"
                            [floatLabel]="'always'">
              <mat-label>CVC code</mat-label>
              <input matInput
                     id="cvc"
                     (focus)="formInputFocus($event)"
                     maxlength="4"
                     formControlName="cvc">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-actions *ngIf="!loading">
    <button mat-button
            (click)="onCancel()"
            color="default">Cancel</button>
    <button mat-raised-button
            (click)="onSave()"
            [disabled]="!creditCardForm.valid"
            color="accent">Save</button>
  </mat-card-actions>
</mat-card>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import isEmpty from 'ramda/src/isEmpty';

import { ApiService } from 'src/app/core/services';
import { DocumentFileInfo } from '../models/document.model';
import { UploadDocumentProps } from '../models/upload-props.interface';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private api: ApiService) { }

  createModel(data: any) {
    if (isEmpty(data)) { return null; }

    const model = new DocumentFileInfo();
    model.hydrate(data);

    return model;
  }

  getDocumentInfo(documentId: string): Observable<DocumentFileInfo> {
    return this.api.get(`documents/${documentId}?alt=media`).pipe(
      map((response) => this.createModel(response.data))
    );
  }

  uploadDocument(props: UploadDocumentProps): Observable<DocumentFileInfo> {
    return this.api.post('documents', props, { headers: { 'Content-Type': 'application/json' } }).pipe(
      map(response => this.createModel(response.data))
    );
  }

  deleteDocument(id: string) {
    return this.api.delete(`documents/${id}`);
  }
}

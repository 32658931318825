import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { EventsService } from './events.service';

class HttpOptions {
  params?: HttpParams | { [param: string]: string | string[]; };
  responseType?: string | any;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private events: EventsService) { }

  get(path: string, options?: HttpOptions): Observable<any> {
    this.removeEmptyParams(options);

    return this.http.get(`${environment.apiUrl}${path}`, options)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  post(path: string, body: object = {}, options: object = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, JSON.stringify(body), options)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, JSON.stringify(body))
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`)
      .pipe(catchError(this.handleErrors.bind(this)));
  }

  private handleErrors(error: any): Observable<any> {
    if (error.status === 401) {
      this.events.emit('api:unauthorized');
      return new Observable();
    } else {
      return throwError(error);
    }
  }

  private removeEmptyParams(options?: HttpOptions) {
    if (options) {
      if (options.params) {
        Object.keys(options.params).forEach(key => {
          switch (true) {
            case (options.params[key] === null):
            case (options.params[key] === undefined):
            case (typeof options.params[key] === 'string' && options.params[key].trim() === ''):
              delete options.params[key];
              break;
          }
        });
      }
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventsService } from '../../../core/services/events.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  pageTitle: string;
  newButton: any;
  activeNotificationBtn:boolean;
  activeActivitiesBtn: boolean;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.layoutService.pageTitleEvent.subscribe((val) => { this.pageTitle = val;});
    this.layoutService.showNewButtonEmitter.subscribe((val) => {
      this.newButton = val;
    })
    this.layoutService.showNotificationsListEmitter.subscribe((val) => {
      this.activeNotificationBtn = val;
    })
    this.layoutService.showActivityLogEmitter.subscribe((val) => {
      this.activeActivitiesBtn = val;
    })
  }
 
  toggleActivitiesList(value){
    this.layoutService.clearRightPanel();
    this.layoutService.showActivityLog(value);
  }

  toggleNotificationsList(value){
    this.layoutService.clearRightPanel();
    this.layoutService.showNotificationsList(value);
  }

}

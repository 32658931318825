import { Injectable } from '@angular/core';

import { Country } from '../interfaces';
import countries from './countries.data.json';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  countries: Country[];

  constructor() {
    this.countries = countries as Country[];
  }

  getCountries(): Country[] {
    return this.countries;
  }

  getCountryByIso2(iso2: string): Country {
    return this.countries.find((country) => iso2.toLowerCase() === country.iso2.toLowerCase());
  }

  getCountryByIso3(iso3: string): Country {
    return this.countries.find((country) => iso3.toLowerCase() === country.iso3.toLowerCase());
  }
}

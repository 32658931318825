import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

import { ApiRequestInterceptor } from './interceptors/api.request.interceptor';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { StorageService } from './services/storage.service';
import { AuthService } from '../modules/auth/services/auth.service';

export function appInit(storage: StorageService, authService: AuthService) {
  return () => storage.init().toPromise().then(() => {
    return Promise.all([
      authService.init().toPromise()
    ]);
  });
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiRequestInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [StorageService, AuthService], multi: true },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { position: 'above' } }
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}

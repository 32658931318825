import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  constructor(private layoutService: LayoutService) {
  }

  ngOnInit() {
  }
  
  searchSubmit(value) {
    if (value && value.length >= 3) {
      this.layoutService.searchSubmit(value);
    }
   
  }
}

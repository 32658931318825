import { BaseModel } from 'src/app/core/models/base.model';

export class DocumentFileInfo extends BaseModel {

    _id: string;
    type: string;
    fileName: string;
    url: string;

    defaults() {
        return {};
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-files',
  templateUrl: './input-files.component.html',
  styleUrls: ['./input-files.component.scss']
})
export class InputFilesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../../layout.service';
import {style, animate, transition, trigger} from '@angular/animations';
import { NavItem } from './nav-item';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1})) 
      ]),
      transition(':leave', [
        animate(300, style({opacity:0})) 
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit {
  @Input() navItems: NavItem[];
  @Input() appsList: any;
  @Input() appLogo: any;
  @Input() homeUrl: any;
  
  fullSideMenu:boolean;
  showAppsList: boolean;

  constructor(private layoutService:LayoutService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.layoutService.toggleSideMenuEvent.subscribe((val) => {
      setTimeout(()=>{                          
        this.fullSideMenu = val;
      }, 100);
    });
    this.layoutService.showAvailableAppsListEmitter.subscribe((val) => {
      setTimeout(()=>{                          
        this.showAppsList = val;
      }, 100);
    });
  }

  openAppsList(){
    this.layoutService.toggleAvailableAppsList(true);
  }

  toggleMenu() {
    this.layoutService.toggleSideMenu(!this.fullSideMenu)
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { GoogleLibPhoneNumberService } from '../services';

@Pipe({
  name: 'formatPhone'
})
export class FormatPhonePipe implements PipeTransform {
  constructor(
    private googleLibPhoneNumberService: GoogleLibPhoneNumberService,
  ) { }

  transform(value: string): string {
    try {
      const phoneNumberInstance = this.googleLibPhoneNumberService.phoneNumberUtil.parseAndKeepRawInput(value);
      return this.googleLibPhoneNumberService.phoneNumberUtil.formatOutOfCountryCallingNumber(phoneNumberInstance);
    } catch (err) {
      return value;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/services/api.service';
import { EventsService } from 'src/app/core/services/events.service';
import { StorageService, STORAGE_TYPE_PERSISTENT, STORAGE_TYPE_SESSION } from 'src/app/core/services/storage.service';
import { AuthToken } from '../models/auth-token.model';
import { UserModel } from '../../users/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: any;

  constructor(private http: HttpClient, private api: ApiService, private storage: StorageService, private events: EventsService) { }

  init() {
    const tokenInfo = this.storage.get('token');
    if (tokenInfo) {
      return this.requestUser().pipe(tap((user: UserModel) => { this.setUser(user); }), catchError(() => of()));
    } else {
      return of();
    }
  }

  login(email: string, password: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${environment.apiUrl}oauth?grant_type=password`, JSON.stringify({ email, password }), { headers }).pipe(
      tap((response: any) => {
        const authToken = new AuthToken();

        authToken.accessToken = response.access_token;
        authToken.refreshToken = response.refresh_token;
        authToken.tokenType = response.token_type;
        authToken.expiresAt = new Date().getTime() + response.expires_in * 1000;

        this.storage.set('token', authToken, { storage: STORAGE_TYPE_SESSION }).subscribe();
      }),
      switchMap(() => this.http.get(`${environment.apiUrl}auth`)),
      tap((response: any) => {
        const user = new UserModel(response.data);
        this.setUser(user);
        this.events.emit('user:login');
      })
    );
  }

  refreshTokens(): Observable<AuthToken> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${environment.apiUrl}oauth?grant_type=refresh_token`, JSON.stringify({
        refresh_token: this.storage.get('token').refreshToken
    }), { headers }).pipe(
        map((response: any) => {
          const authToken = new AuthToken();

          authToken.accessToken = response.access_token;
          authToken.refreshToken = response.refresh_token;
          authToken.tokenType = response.token_type;
          authToken.expiresAt = new Date().getTime() + response.expires_in * 1000;

          return authToken;
        })
    );
  }

  remember() {
    return this.storage.set('token', this.storage.get('token'), { storage: STORAGE_TYPE_PERSISTENT });
  }

  logout() {
    return this.storage.remove('token').pipe(tap(() => {
      this.user = null;
      this.events.emit('user:logout');
    }));
  }

  getUser(): UserModel | null {
    return this.user;
  }

  setUser(user: UserModel) {
    this.user = user;
  }

  isAuthenticated() {
    return (this.user instanceof UserModel);
  }

  requestPasswordReset(email: string) {
    return this.api.post('auth/reset', { email });
  }

  private requestUser() {
    return this.http.get(`${environment.apiUrl}auth`).pipe(map((response: any) => new UserModel(response.data)));
  }
}

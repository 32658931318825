import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErpLayouComponent } from './layout/erp/erp.component';
import { AuthGuard } from './core/guards/auth.guard';
import { TicketingLayouComponent } from './layout/ticketing/ticketing.component';
import {PageNotFoundComponent} from "./modules/pages/components/page-not-found/page-not-found.component";
import { DemoLayoutComponent } from './layout/demo/demo-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ticketing',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'customers',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'receivables',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/receivables/receivables.module').then(m => m.ReceivablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inventory',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vendors',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/vendors/vendors.module').then(m => m.VendorsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payables',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/payables/payables.module').then(m => m.PayablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lead-lists',
    component: ErpLayouComponent,
    loadChildren: () => import('./modules/leads/leads.module').then(m => m.LeadsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ticketing',
    component: TicketingLayouComponent,
    loadChildren: () => import('./modules/ticketing/ticketing.module').then(m => m.TicketingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'demo',
    component: DemoLayoutComponent,
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div [formGroup]="addressForm">

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100">
        <textarea formControlName="address"
                  matInput
                  class="address"
                  [rows]="6"
                  cdkTextareaAutosize
                  (focusout)="addressChanged()"
                  [placeholder]="addressLabel"> </textarea>
        <app-form-control-error [control]="this.addressForm.get('address')"
                                controlFriendlyName="Address"
                                matSuffix>
        </app-form-control-error>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field class="w-100">
        <mat-label>Country *</mat-label>
        <mat-select formControlName="country">
          <mat-option *ngFor="let country of countries"
                      (focusout)="addressChanged()"
                      [value]="country.iso3">{{country.countryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row city">
    <div class="col-md-18 offset-md-18 col-lg-18 offset-lg-18">
      <mat-form-field class="w-100">
        <input matInput
               (focusout)="addressChanged()"
               formControlName="city"
               placeholder="City *">
        <app-form-control-error [control]="this.addressForm.get('city')"
                                controlFriendlyName="City"
                                matSuffix>
        </app-form-control-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">

    <div class="col">
      <mat-form-field class="w-100">
        <textarea formControlName="notes"
                  class="notes"
                  matInput
                  cdkTextareaAutosize
                  placeholder="Address Notes / SM">
                  </textarea>
      </mat-form-field>
    </div>

    <div class="col">

      <div class="row">
        <div class="col-md-18 col-lg-18">
          <mat-form-field *ngIf="selectedCountryStates().length"
                          class="w-100">
            <mat-label>State *</mat-label>
            <mat-select formControlName="state">
              <mat-option *ngFor="let state of selectedCountryStates()"
                          (focusout)="addressChanged()"
                          [value]="state.code">{{state.code}}
              </mat-option>
            </mat-select>

            <app-form-control-error [control]="this.addressForm.get('state')"
                                    controlFriendlyName="State"
                                    matSuffix>
            </app-form-control-error>
          </mat-form-field>

          <mat-form-field *ngIf="!selectedCountryStates().length"
                          class="w-100">
            <mat-label>State</mat-label>
            <input matInput
                   (focusout)="addressChanged()"
                   formControlName="state"
                   placeholder="State">
          </mat-form-field>
        </div>
        <div class="col-md-18 col-lg-18">
          <mat-form-field class="w-100">
            <input matInput
                   formControlName="zip"
                   placeholder="Zip *">
            <app-form-control-error [control]="this.addressForm.get('zip')"
                                    controlFriendlyName="Zip"
                                    matSuffix>
            </app-form-control-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="row"
       *ngIf="showAddressNotValidatedMessage">
    <div class="col">
      <div class="banner">
        <div class="symbol">
          <mat-icon> warning </mat-icon>
        </div>
        <div class="message">
          This address was not validated. Click <span class="verify-action"
                (click)="verifyAddress();"> here </span> to validate.
        </div>
      </div>
    </div>
  </div>

  <div class="row"
       *ngIf="showAddressCheckedBySmartyMessage">
    <div class="col">
      <div class="banner error"
           *ngIf="!smartySuggestions.length">
        <div class="symbol">
          <mat-icon> warning </mat-icon>
        </div>
        <div class="message">
          <p>
            The address you entered could not be validated. Do you want to continue to use this address?
          </p>
          <mat-checkbox (change)="onAddressUserValidateCheckboxChanged($event)">
            I understand and I want to continue with
            this address
          </mat-checkbox>
        </div>
      </div>

      <div class="banner"
           *ngIf="smartySuggestions.length">
        <div class="symbol">
          <mat-icon> warning </mat-icon>
        </div>

        <div class="message">
          <p>
            The address you entered matched following addresses. Please select your address from the list below:
          </p>
          <ul class="suggestions">
            <li *ngFor="let smartySuggestion of smartySuggestions">
              <span class="address">
                {{smartySuggestion.delivery_line_1}}, {{smartySuggestion.components.city_name}},
                {{smartySuggestion.components.state_abbreviation}}
                {{smartySuggestion.components.zipcode}}-{{smartySuggestion.components.plus4_code}}
              </span>
              <span class="action">
                <button (click)="useSuggestion(smartySuggestion)"
                        mat-button
                        color="accent"
                        class="flex-fill">Use this address</button>
              </span>
            </li>
          </ul>
        </div>

        <mat-checkbox (change)="onAddressUserValidateCheckboxChanged($event)">I want to continue with entered address
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>

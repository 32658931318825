import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public showActivityLogEmitter = new EventEmitter();
  public onSearchSubmit = new EventEmitter();
  public pageTitleEvent = new EventEmitter();
  public showNewButtonEmitter = new EventEmitter();
  public showActivityLogBtnEvent = new EventEmitter();
  public toggleSideMenuEvent = new EventEmitter();
  public showNotificationsListEmitter = new EventEmitter();
  public showAvailableAppsListEmitter = new EventEmitter();

  constructor() { }

  public pageTitle = '';

  showActivityLog(val) {
    this.showActivityLogEmitter.emit(val);
  }

  showNotificationsList(val){
    this.showNotificationsListEmitter.emit(val);
  }

  clearRightPanel(){
    this.showActivityLog(false);
    this.showNotificationsList(false);
  }

  searchSubmit(val){
    this.onSearchSubmit.emit(val);
  }

  setPageTitle(title) {
    this.pageTitleEvent.emit(title);
  }

  showNewButton(val){
    this.showNewButtonEmitter.emit(val);
  }

  showActivityLogButton(val){
    this.showActivityLogBtnEvent.emit(val);
  }

  toggleSideMenu(val){
    this.toggleSideMenuEvent.emit(val);
  }

  toggleAvailableAppsList(val) {
    this.showAvailableAppsListEmitter.emit(val);
  }

}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { ErpLayouComponent } from './erp/erp.component';
import { TicketingLayouComponent } from './ticketing/ticketing.component';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DemoLayoutComponent } from './demo/demo-layout.component';

@NgModule({
  declarations: [
    ErpLayouComponent,  
    TicketingLayouComponent, DemoLayoutComponent   
  ],
  imports: [
    RouterModule,
    CommonModule,
    MaterialModule,
    ComponentsModule,
    HttpClientModule
  ],
  exports:[
    ErpLayouComponent,
    TicketingLayouComponent,
    DemoLayoutComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LayoutModule { }

import { BaseModel } from './base.model';

export class PermissionsModel extends BaseModel {
  methods: {
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  };
  properties: {
    [key: string]: {
      create: boolean;
      read: boolean;
      update: boolean;
      delete: boolean;
    };
  };

  constructor(data?: any) {
    super(data);
  }

  defaults() {
    return {};
  }
}

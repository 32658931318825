import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {style, animate, transition, trigger} from '@angular/animations';
import { EventsService } from 'src/app/core/services';
import { NavItem } from '../components/sidebar/nav-item';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-demo-layout',
  templateUrl: './demo-layout.component.html',
  styleUrls: ['./demo-layout.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1})) 
      ]),
      transition(':leave', [
        animate(300, style({opacity:0})) 
      ])
    ])
  ]
})
export class DemoLayoutComponent implements OnInit {

 
  showHeader: boolean;
  lastScrollTop: number;
  stickyHeader = true;
  acitivityLogOpened: boolean;
  sideMenuOpened: boolean;
  notificationsListOpened: boolean;
  navItems: NavItem[];
  appsList: any;

  constructor(private eventsService: EventsService, private layoutService: LayoutService, private router: Router,
              private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.showHeader = false;
    this.stickyHeader = false;
    this.lastScrollTop = 0;
  }

  ngOnInit() {
       
    this.matIconRegistry.addSvgIcon(
      'erpLogo',
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/icons/erp_logo.svg")
    );

    this.navItems = [
      {
        text: 'Demo',
        icon: 'people_alt',
        url: '/demo/cards',
        children: [
          {
            text: 'Cards',
            url: '/demo/cards'
          },
          {
            text: 'Forms',
            url: '/demo/forms'
          },
          {
            text: 'Modals',
            url: '/demo/modals'
          },
          {
            text: 'Generic tables',
            url: '/demo/generic-tables'
          },
          {
            text: 'Autocomplete chips',
            url: '/demo/autocomplete-chips'
          },
          {
            text: 'Tables',
            url: '/demo/tables'
          },
          {
            text: 'Stepper',
            url: '/demo/stepper'
          }
        ]
      }
    ];

    this.appsList = {
      active: [
        {
          name: 'Ticketing System',
          icon: 'taskManager',
          url: 'https://ticketing.phillipscollection.com/' 
        }
      ], 
      future: []
    }
  
    window.addEventListener('scroll', this.scrollEvent, true);
    window.addEventListener('resize', this.resizeEvent, true);

    this.eventsService
      .subscribe('stickyHeader', ((isSticky: boolean) => {
        this.stickyHeader = isSticky;
      }));

    this.layoutService.showActivityLogEmitter.subscribe((val) => { this.acitivityLogOpened = val; });
    this.layoutService.showNotificationsListEmitter.subscribe((val) => { this.notificationsListOpened = val; });
    this.layoutService.toggleSideMenuEvent.subscribe((val) => { 
      setTimeout(()=>{                          
        this.sideMenuOpened = val;
      }, 100);
     });
  }


  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
    window.removeEventListener('resize', this.resizeEvent, true);
  }

  scrollEvent = (event: any): void => {
    if (this.stickyHeader) {
      this.showHeader = true;
      return;
    }

    let st = event.srcElement.scrollTop;
    if (st > this.lastScrollTop) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
    this.lastScrollTop = st;
  }

  resizeEvent  = (event: any): void => {
    let innerWidth = event.srcElement.innerWidth;
    
    if (innerWidth < 1440) {
      this.layoutService.toggleSideMenu(false);
    }
  }

}
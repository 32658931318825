import { BaseModel } from 'src/app/core/models/base.model';

export class CreditCardModel extends BaseModel {

  id: string;
  creditCard: {
    number: string;
    holderName: string;
    expiration: {
      month: number;
      year: number;
    };
    cvc: number;
    type: string;
  };

  defaults() {
    return {
      creditCard: {
        number: '',
        holderName: '',
        expiration: {
          month: '',
          year: '',
        },
        cvc: '',
        type: '',
      }
    };
  }

  constructor(data?: any) {
    super(data);
  }
}

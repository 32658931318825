import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Directive({
  exportAs: 'defaultImage',
  selector: '[defaultImage]'
})
export class DefaultImageDirective implements OnInit {
  @Input() defaultImage;
  @Input() size: string;
  defaultSrc: string;
  placeholder;
  sizes: string[];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private http: HttpClient
  ) {
    this.sizes = ['tn', 'sm', 'md', 'lg'];
    if (!Number(this.size)) {
      if (this.sizes.indexOf(this.size) === -1) {
        this.size = 'sm';
      }
    }
    this.defaultSrc = 'assets/img/placeholders/' + this.size + '.jpg';
    this.placeholder = new Image();
  }

  ngOnInit() {
    this.setSrc();
  }

  setSrc() {
    const hasImages = this.defaultImage && this.defaultImage.assets && this.defaultImage.assets.images;
    const mainImage = hasImages && this.defaultImage.assets.images.main;
    const firstDetailsImage = hasImages && this.defaultImage.assets.images.details && this.defaultImage.assets.images.details[0];
    const firstLifeStyleImage = hasImages && this.defaultImage.assets.images.lifestyle && this.defaultImage.assets.images.lifestyle[0];
    let src = mainImage || firstDetailsImage || firstLifeStyleImage;

    if (src) {
      if (Number(this.size)) {
        src += 'resize?size=' + this.size;
      } else {
        const slices = this.sizes.slice(this.sizes.indexOf(this.size));
        let srcSet = '';

        slices.forEach((slice, index) => {
          index++;
          srcSet += src + slice + ' ' + index + 'x, ';
        });

        if (srcSet) {
          this.renderer.setAttribute(this.el.nativeElement, 'srcset', srcSet.replace(/,\s*$/, ''));
        }
      }
      this.placeholder.setAttribute('src', src);
      this.placeholder.onload = () => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', src);
      };
      this.placeholder.onerror = () => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultSrc);
      };
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultSrc);
    }
  }
}

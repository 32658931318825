import { CdkStepper, CdkStep } from '@angular/cdk/stepper';
import { Component, ContentChildren, QueryList, ChangeDetectionStrategy, Input, ViewEncapsulation, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-step',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  providers: [
    { provide: CdkStep, useExisting: StepComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepComponent extends CdkStep  implements AfterContentInit{

  /** Icon of the step. */
  @Input() icon: string;

  ngAfterContentInit() {
    console.log(this);
  }
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    { provide: CdkStepper, useExisting: StepperComponent }
  ],
  encapsulation: ViewEncapsulation.None
})
export class StepperComponent extends CdkStepper {

  /** Full list of steps inside the stepper, including inside nested steppers. */
  @ContentChildren(StepComponent, {descendants: true}) _steps: QueryList<StepComponent>;

  /** Steps that belong to the current stepper, excluding ones from nested steppers. */
  readonly steps: QueryList<StepComponent> = new QueryList<StepComponent>();

 

}

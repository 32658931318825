
<div class="page-content-wrapper bg-white" [ngClass]="{'side-menu-extended':sideMenuOpened}">

    <div class="sidebar">
      <app-sidebar [navItems]="navItems" [appsList]="appsList" [appLogo]="'taskManagerLogo'" [homeUrl]="'/ticketing'"></app-sidebar>
    </div>
  
    <div class="content-wrapper" [ngClass]="'visibleHeader'">
      <div class="main-content-wrapper">
        <app-header></app-header>
        <div class="main-content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="side-content" *ngIf="acitivityLogOpened || notificationsListOpened" [@fadeInOut]>
        <div>
          <app-timeline *ngIf="acitivityLogOpened"></app-timeline>
          <app-notifications-list *ngIf="notificationsListOpened"></app-notifications-list>
        </div>
      </div>
    </div>
  
  </div>
  
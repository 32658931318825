<mat-card class="modal-content">
  <mat-card-header class="bg-primary-100 border-bottom rounded-top  border-primary-600 d-flex">
    <mat-card-title class="my-0">
      {{data.header}}
    </mat-card-title>
    <mat-card-subtitle>{{data.subHeader}}</mat-card-subtitle>
    <button mat-icon-button class="close" (click)="onCancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content  class="ml-n3 mr-n3 pb-4">

    <ng-container *ngIf="isTemplateRef">
      <ng-container *ngTemplateOutlet="data.content"></ng-container>
    </ng-container>

    <ng-container *ngIf="!isTemplateRef">
      <p [innerHTML]="data.content"></p>
    </ng-container>

    <ng-container *ngIf="data.inputs">
      <form [formGroup]="form">
        <ng-container *ngFor="let input of data.inputs">
            <mat-form-field *ngIf="input.type === undefined || input.type === 'text'" class="w-100">
              <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder">
              <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
            </mat-form-field>
            <mat-form-field *ngIf="input.type === 'number'" class="w-100">
              <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder" type="number">
              <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
            </mat-form-field>
            <mat-form-field *ngIf="input.type === 'date'" class="w-100">
              <input matInput [id]="input.id" [formControlName]="input.name" [placeholder]="input.placeholder" type="date">
              <app-form-control-error [control]="form.get(input.name)" [controlFriendlyName]="input.name" matSuffix></app-form-control-error>
            </mat-form-field>
        </ng-container>
      </form>
    </ng-container>
  </mat-card-content>

  <mat-card-actions>
    <ng-container *ngFor="let button of data.buttons">
      <button mat-flat-button [class]="button.cssClass" [color]="button.color" [disabled]="button.disabled" (click)="onButtonClick(button)">{{ button.text }}</button>
    </ng-container>
  </mat-card-actions>
</mat-card>

import { UserModel } from '../../../modules/users/models/user.model';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  showMenu: boolean;
  currentUser: UserModel;
  constructor(private authService: AuthService) {
    this.showMenu = false;
    this.currentUser = this.authService.getUser();
  }

  ngOnInit() {
  }

  logOut() {
    this.authService.logout().subscribe();
  }

  toggle() {
    this.showMenu = ! this.showMenu;
  }
}

import { Component, OnInit, Input, Output, HostListener, OnDestroy, ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const FILTER_TYPE_INPUT = 'input';
export const FILTER_TYPE_RADIO = 'radio';
export const FILTER_TYPE_MULTI_SELECT = 'multiSelect';
export const FILTER_TYPE_RANGE = 'range';

export interface ListFilter {
  id: string;
  name: string;
  type: string;
  query: string[];
  values?: (number | string | boolean)[];
  searchEnabled?: boolean;
  onUpdate?: (values: any[]) => any[];
  onInputChange?: (values: any[]) => void;
  onRemove?: () => void;
  input?: {
    type?: 'text' | 'email' | 'number' | 'tel';
    placeholder?: string;
    hint?: string;
  };
  options?: {
    label: string;
    value: number | string | boolean;
  }[];
  range?: {
    type?: 'number' | 'date';
  };
}

export interface FiltersChangeEvent {
  filters: ListFilter[];
  query: string;
}

@Component({
  selector: 'app-list-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class ListFiltersComponent implements OnInit, OnDestroy, OnChanges {

  TYPE_INPUT = FILTER_TYPE_INPUT;
  TYPE_RADIO = FILTER_TYPE_RADIO;
  TYPE_MULTI_SELECT = FILTER_TYPE_MULTI_SELECT;
  TYPE_RANGE = FILTER_TYPE_RANGE;

  @Input()
  filters: ListFilter[];
  @Input()
  defaultAppliedFilters: ListFilter[] = [];
  @Input()
  applied: ListFilter[];
  @Input()
  query: string;

  @Output()
  addFilter: EventEmitter<ListFilter> = new EventEmitter();
  @Output()
  removeFilter: EventEmitter<ListFilter> = new EventEmitter();
  @Output()
  resetFilters: EventEmitter<void> = new EventEmitter();

  selected: ListFilter;
  available: ListFilter[];
  prettyValues: { [key: string]: string } = {};

  searchTerm: string = '';

  private clicked: boolean;
  private overlay: boolean;

  private isOpenBehavior: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() isOpen: Observable<boolean> = this.isOpenBehavior.asObservable();

  constructor(private elementRef: ElementRef) {

  }

  ngOnInit() {
    this.available = this.filters;

    this.applied.forEach((filter: ListFilter) => {
      this.prettyValues[filter.id] = this.getFilterPrettyValue(filter);
    });

    window.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.applied && changes.applied.currentValue !== changes.applied.previousValue) {
      this.available = this.filters.filter((filter: ListFilter) => {
        return !changes.applied.currentValue.find((appliedFilter: ListFilter) => appliedFilter.id === filter.id);
      });
    }

    if (changes && changes.filters && changes.filters.currentValue !== changes.filters.previousValue) {
      this.available = changes.filters.currentValue.filter((filter: ListFilter) => {
        return !this.applied.find((appliedFilter: ListFilter) => appliedFilter.id === filter.id);
      });
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll.bind(this), true);
  }

  open() {
    this.selected = null;
    this.isOpenBehavior.next(true);
  }

  select(filter: ListFilter) {
    filter.values = [];
    this.selected = filter;
  }

  apply(filter: ListFilter) {
    if (!this.validateValue(filter.values)) {
      return;
    }

    this.addFilter.emit(filter);

    this.available = this.available.filter((el) => el !== filter);
    this.prettyValues[filter.id] = this.getFilterPrettyValue(filter);
    this.searchTerm = '';

    if (this.isOpenBehavior.value && !!this.selected) {
      this.isOpenBehavior.next(false);
      this.selected = null;
    }

    if (filter.onInputChange) {
      filter.onInputChange(filter.values);
    }
  }

  remove(filter: ListFilter) {
    this.removeFilter.emit(filter);
    this.available = this.filters.filter((el) => !this.applied.includes(el));

    if (filter.onRemove) {
      filter.onRemove();
    }
  }

  resetAll() {
    this.resetFilters.emit();

    this.available = this.filters;

    if (this.isOpenBehavior.value) {
      this.isOpenBehavior.next(false);
    }
  }

  setSearchTerm(value: string) {
    this.searchTerm = value;
  }

  private onScroll() {
    if (this.elementRef.nativeElement.getBoundingClientRect().top <= -this.elementRef.nativeElement.offsetHeight && this.isOpenBehavior.value) {
      this.isOpenBehavior.next(false);
    }
  }

  private openOverlay() {
    this.overlay = true;
  }

  private closedOverlay() {
    this.overlay = false;
  }

  private clickInside() {
    this.clicked = true;
  }

  @HostListener('document:click')
  private clickOut() {
    if (!this.clicked && !this.overlay && this.isOpenBehavior.value) {
      this.isOpenBehavior.next(false);
    }
    this.clicked = false;
  }

  private endOfDay(event) {
    return (event.value as Date).setHours(23, 59, 59, 999);
  }

  private setFilterValue(filter: ListFilter, value: any, index?: number) {
    if (!index) {
      index = 0;
    }
    filter.values[index] = (value instanceof Date) ? value.getTime() : value;
  }

  private toggleValue(filter: ListFilter, value: ListFilter['values'][0], event: MouseEvent) {
    event.preventDefault();

    if (!Array.isArray(filter.values)) {
      filter.values = [value];
    } else if (filter.values.includes(value)) {
      filter.values = filter.values
        .filter(el => el !== value);
    } else {
      filter.values = filter.options
        .filter(el =>
          [].concat(filter.values, [value])
            .includes(el.value))
        .map(el => el.value);
    }
  }

  private getFilterPrettyValue(filter: ListFilter): string {
    let value = 'N/A';
    switch (filter.type) {
      case FILTER_TYPE_INPUT:
        value = filter.values.toString();
        break;

      case FILTER_TYPE_RADIO:
        value = filter.options.filter(option => filter.values[0] === option.value).map(el => el.label).toString();
        break;

      case FILTER_TYPE_MULTI_SELECT:
        value = filter.options.filter(option => filter.values.includes(option.value)).map(el => el.label).join(', ');
        break;

      case FILTER_TYPE_RANGE:
        const values = filter.values.map(val => {
          if (val === '' || (typeof val !== 'string' && typeof val !== 'number' && typeof val !== 'boolean')) {
            return null;
          } else if (filter.range.type === 'date') {
            const date = new Date();
            date.setTime(val as number);
            return date.toLocaleDateString();
          } else {
            return val.toString();
          }
        });
        if (!!values[0] && !!values[1]) {
          value = 'from ' + values[0] + ' to ' + values[1];
        } else if (!values[0]) {
          value = (filter.range.type === 'date' ? 'before ' : 'lower than ') + values[1];
        } else if (!values[1]) {
          value = (filter.range.type === 'date' ? 'from ' : 'greater than ') + values[0];
        }
        break;

      default:
        return 'N/A';
    }

    return value;
  }

  private validateValue(value: any[]): boolean {
    const valid = value.filter(el => {
      return !(el === '' || (typeof el !== 'string' && typeof el !== 'number' && typeof el !== 'boolean'));
    });

    return valid.length > 0;
  }
}

<div class="input-chips-container">
  <mat-chip-list #chipsList [selectable]="false" [disabled]="_disabled">
    <mat-chip *ngFor="let selected of _selectedOptions; let i = index" (removed)="onChipRemove(i)" class="default">
      {{ selected | map:displayChip:displayWith:matOptions }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input matInput #input
            [formControl]="_inputControl"
            [placeholder]="_placeholder"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="onInputTokenEnd($event)">
    <app-form-control-error [control]="_inputControl" controlFriendlyName="Chips" matSuffix></app-form-control-error>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of _filteredOptions | async" [value]="option.value">{{ option.viewValue }}</mat-option>
  </mat-autocomplete>
</div>
import { Component, ContentChildren, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, QueryList, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, FormGroupDirective } from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption, ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FocusMonitor } from '@angular/cdk/a11y';

import { map, startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InputChipsComponent }]
})
export class InputChipsComponent implements OnInit, OnDestroy, MatFormFieldControl<any>, ControlValueAccessor {

  static nextId = 0;
  @HostBinding() id = `input-chips-${InputChipsComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() displayWith: ((value: any) => string) | null;

  @Input() filterWith: ((optionValue: any, filterValue: string) => boolean) | null;

  // tslint:disable-next-line: no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  get value(): any[] {
    return this._selectedOptions;
  }
  set value(value: any[]) {
    this._selectedOptions = value;
    this.stateChanges.next();
  }

  get empty() {
    return !this._inputControl?.value && !this._selectedOptions?.length;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  _disabled = false;

  get errorState(): boolean {
    if (this.ngControl) {
      return this.errorStateMatcher.isErrorState(this.ngControl.control as FormControl, this.ngControl.control.parent as unknown as FormGroupDirective);
    } else {
      return false;
    }
  }

  @ContentChildren(MatOption) matOptions: QueryList<MatOption>;
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;

  focused = false;
  stateChanges = new Subject<void>();
  controlType = 'app-input-chips';

  _inputControl = new FormControl();
  _filteredOptions: Observable<MatOption[]>;
  _selectedOptions: any[] = [];

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl, private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>, private errorStateMatcher: ErrorStateMatcher) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this._focusMonitor.monitor(this._elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit() {
    this._filteredOptions = this._inputControl.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value))
    );
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector('.input-chips-container');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._focusMonitor.focusVia(this._inputElement, 'program');
    }
  }

  writeValue(value: any[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  displayChip(value, displayWith, matOptions) {
    if (displayWith) {
      return displayWith(value);
    }

    const foundOption = matOptions.find((opt) => JSON.stringify(value) === JSON.stringify(opt.value));
    if (foundOption) {
      return foundOption.viewValue;
    }

    return value.toString();
  }

  onChipRemove(index: number): void {
    if (index >= 0) {
      this._selectedOptions.splice(index, 1);

      this.onChange(this.value);
      this.stateChanges.next();
    }
  }

  onInputTokenEnd(event) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this._selectedOptions.push(value);
    }

    if (input) {
      input.value = '';
    }

    this._inputControl.setValue(null);

    this.onChange(this.value);
    this.stateChanges.next();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this._selectedOptions.push(event.option.value);
    this._inputElement.nativeElement.value = '';
    this._inputControl.setValue(null);

    this.onChange(this.value);
    this.stateChanges.next();
  }

  private _filter(value: any): MatOption[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';

    if (this.filterWith) {
      return this.matOptions.filter(option => this.filterWith(option.value, filterValue));
    } else {
      return this.matOptions.filter(option => option.viewValue.toLowerCase().includes(filterValue));
    }
  }

}
